<template>
  <form @submit.stop.prevent>
    <div class="input-group header-right-search">
      <input type="text" name="q" autocomplete="off"
             class="form-control header-right-search-form"
             @input="onChange"
             v-model="search"
             @keydown.down="onArrowDown"
             @keydown.up="onArrowUp"
             @keydown.enter="onSumbit"
             :placeholder="searchTranslation"
      />
      <div class="autocomplete" v-show="isOpen">
        <ul
            id="autocomplete-results"
            class="autocomplete-items"
        >
          <li
              class="loading"
              v-if="isLoading"
          >
            Loading results...
          </li>
          <li
              v-else
              v-for="(result, i) in results"
              :key="i"
              @click="setResult(result)"
              class="item"
              v-bind:class="{ 'active': i === arrowCounter }"
          >
            {{ result.productnumber }}
          </li>
        </ul>
      </div>
      <div class="input-group-btn">
        <button
            class="btn header-right-search-button"
            @click="onSumbit">
          <span class="sr-only">{{searchTranslation}}</span>
        </button>
      </div>
    </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'SearchAutocomplete',
  props: {
    isAsync: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchUrl: {
      type: String,
      required: true,
      default: 'en',
    },
    searchTranslation: {
      type: String,
      required: true,
      default: 'en',
    },
  },
  data()
  {
    return {
      isOpen: false,
      results: [],
      search: '',
      isLoading: false,
      arrowCounter: -1,
      items: []
    };
  },
  watch: {
    items: function (value, oldValue) {
      this.results = value;
      this.isOpen = true;
      this.isLoading = false;
    },
  },
  mounted()
  {
    document.addEventListener('click', this.handleClickOutside)

    // Getting q get param
    const params = new URLSearchParams(location.search)
    if (params.get('keyword') !== '') {
      this.search = params.get("keyword")
    }
  },
  destroyed()
  {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    setResult(result)
    {
      window.location.href = this.searchUrl + result.productnumber;
    },
    onChange()
    {
      this.$emit('input', this.search);

      if (this.search.length > 1) {

        axios.get('/api/search', {params: {query: this.search}}).then(response => {
          this.items = response.data;
        });

        if (this.isAsync) {
          this.isLoading = true;
        } else {
          this.isOpen = true;
        }

      } else {
        this.isOpen = false;
        this.items = []
      }
    },
    handleClickOutside(event)
    {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
    onArrowDown()
    {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      } else {
        this.arrowCounter = 0;
      }
    },
    onArrowUp()
    {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      } else {
        this.arrowCounter = this.results.length - 1;
      }
    },
    onSumbit()
    {
      if (this.results[this.arrowCounter]) {
        this.search = this.results[this.arrowCounter].productnumber;
        this.isOpen = false;
        this.arrowCounter = -1;
      }

      window.location.href = this.searchUrl + this.search
    }
  },
};
</script>
